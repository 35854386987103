import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, H4, Wrapper } from '@raysync/common/components'
import { LOCALES } from '@raysync/common/services'
import Layout from '../../components/layout'

import {
  SectionDesc,
  FeatureSection,
  Section,
  SolutionDivider,
  ApplicationSection,
  ApplicationContainer,
  ApplicationItem,
  ArchSection,
} from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/data-migr-feature1'
import Feature2 from '@raysync/common/components/svg/data-migr-feature2'
import Feature3 from '@raysync/common/components/svg/data-migr-feature3'
import Feature4 from '@raysync/common/components/svg/data-migr-feature4'

import App1 from '@raysync/common/components/svg/data-migr-app1'
import App2 from '@raysync/common/components/svg/data-migr-app2'
import App3 from '@raysync/common/components/svg/data-migr-app3'
import App4 from '@raysync/common/components/svg/data-migr-app4'

import Arch from '@raysync/common/components/svg/solutions/applications/data-migr-arch'
import ArchEn from '@raysync/common/components/svg/solutions/applications/data-migr-arch-en'
import Banner from '@raysync/common/components/svg/solutions/applications/data-migr-banner'

class MultiTransfer extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('solution5.feature.item1.title'),
        desc: intl.get('solution5.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('solution5.feature.item2.title'),
        desc: intl.get('solution5.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('solution5.feature.item3.title'),
        desc: intl.get('solution5.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('solution5.feature.item4.title'),
        desc: intl.get('solution5.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    applicationList: [
      {
        point: intl.get('solution5.scenes.point1'),
        svg: <App1 />,
      },
      {
        point: intl.get('solution5.scenes.point2'),
        svg: <App2 />,
      },
      {
        point: intl.get('solution5.scenes.point3'),
        svg: <App3 />,
      },
      {
        point: intl.get('solution5.scenes.point4'),
        svg: <App4 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList, applicationList } = this.state
    const ArchPic = {
      [LOCALES.ZH]: <Arch />,
      [LOCALES.EN]: <ArchEn />,
      [LOCALES.JA]: <ArchEn />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='solution5' location={location}>
        <Swiper>
          <SolutionBanner type='solution5' pic={<Banner />} />
        </Swiper>
        <Section>
          <CommonTitle>{intl.get('solution5.section.title')}</CommonTitle>
          <SectionDesc top={25} bottom={18}>
            {intl.get('solution5.section.desc1')}
          </SectionDesc>
          <SectionDesc>{intl.get('solution5.section.desc2')}</SectionDesc>
          <SectionDesc bottom={48}>{intl.get('solution5.section.desc3')}</SectionDesc>
          <SolutionDivider />
        </Section>
        <FeatureSection>
          <CommonTitle>{intl.get('solution5.feature.title')}</CommonTitle>
          <SectionDesc top={12} bottom={26}>
            {intl.get('solution5.feature.desc')}
          </SectionDesc>
          <List data={featureList} />
        </FeatureSection>
        <ArchSection>
          <Wrapper>
            <CommonTitle>{intl.get('solution5.arch.title')}</CommonTitle>
            <div>{ArchPic}</div>
          </Wrapper>
        </ArchSection>
        <ApplicationSection>
          <CommonTitle>{intl.get('solution5.scenes.title')}</CommonTitle>
          <ApplicationContainer>
            {applicationList.map((l, i) => (
              <ApplicationItem key={i}>
                <div>{l.svg}</div>
                <H4>{l.point}</H4>
              </ApplicationItem>
            ))}
          </ApplicationContainer>
        </ApplicationSection>
        <Swiper>
          <InviteBanner type='solution5' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default MultiTransfer

export const query = graphql`
  query {
    adBanner: file(relativePath: { regex: "/industry/ad_banner/" }) {
      ...fluidImage
    }
  }
`
