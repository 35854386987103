import React from 'react'

const SvgDataMigrFeature2 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <circle cx={25} cy={25} r={23} fill='none' stroke='#334966' strokeWidth={2} />
    <circle cx={25} cy={25} r={10} fill='#fd5c1f' />
    <path
      d='M27.03 21.55l5.75-5.75 1.41 1.41-5.75 5.75c.91 1.53.7 3.54-.62 4.86a4.008 4.008 0 01-5.66 0 4.008 4.008 0 010-5.66 4.036 4.036 0 014.87-.61zM12.27 37.73c-7.03-7.03-7.03-18.43 0-25.46s18.43-7.03 25.46 0 7.03 18.43 0 25.46-18.43 7.03-25.46 0zm1.42-1.42c6.25 6.25 16.38 6.25 22.63 0s6.25-16.38 0-22.63-16.38-6.25-22.63 0-6.25 16.39 0 22.63zm9.9-9.9c.78.78 2.05.78 2.83 0 .78-.78.78-2.05 0-2.83-.78-.78-2.05-.78-2.83 0-.79.79-.79 2.05 0 2.83z'
      fill='#334966'
    />
  </svg>
)

export default SvgDataMigrFeature2
