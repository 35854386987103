import React from 'react'

const SvgDataMigrFeature1 = props => (
  <svg id='dataMigr_feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.dataMigr_feature1_svg__st0{fill:#fd5c1f}.dataMigr_feature1_svg__st1{fill:#334966}'}</style>
    <g id='dataMigr_feature1_svg__\u6269\u5C55-48x48'>
      <path id='dataMigr_feature1_svg__Fill-2_7_' className='dataMigr_feature1_svg__st0' d='M18 36h18V18H18z' />
      <path
        id='dataMigr_feature1_svg__Fill-3_4_'
        className='dataMigr_feature1_svg__st1'
        d='M28 14v2h4.59L16 32.59V28h-2v8h8v-2h-4.59L34 17.41V22h2v-8h-2z'
      />
      <path id='dataMigr_feature1_svg__Fill-4_5_' className='dataMigr_feature1_svg__st1' d='M34 34h-6v2h8v-8h-2z' />
      <path id='dataMigr_feature1_svg__Fill-5_3_' className='dataMigr_feature1_svg__st0' d='M16 16h6v-2h-8v8h2z' />
      <circle
        id='dataMigr_feature1_svg__Oval-7_7_'
        cx={25}
        cy={25}
        r={23}
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
    </g>
  </svg>
)

export default SvgDataMigrFeature1
