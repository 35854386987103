import React from 'react'

const SvgDataMigrFeature4 = props => (
  <svg id='dataMigr_feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.dataMigr_feature4_svg__st0{fill:#2b4b67}.dataMigr_feature4_svg__st1{fill:#fd5c1f}'}</style>
    <path
      className='dataMigr_feature4_svg__st0'
      d='M24.78 48l-.53-.2c-4.77-1.94-8.52-4.7-13.38-9.85-4.61-4.87-7.04-13.2-6.85-23.47.01-.12.01-.18.01-.29v-2.48l2.45-.67c5.17-1.43 10.58-4.07 17.55-8.53L24.8 2l.8.51c6.56 4.16 12.59 7.03 17.93 8.51l2.45.67v2.42c.01.22.01.28.01.42.19 10.69-2.01 18.79-6.38 23.4-4.11 4.35-8.91 7.67-14.27 9.86l-.56.21zM6.02 13.23v.96c0 .16 0 .26-.01.37-.18 9.7 2.07 17.54 6.31 22.03 4.58 4.85 8.09 7.47 12.45 9.28 5-2.07 9.51-5.2 13.37-9.3 4.01-4.23 6.03-11.85 5.84-22.02 0-.14 0-.19-.01-.36v-.98l-.97-.28c-5.45-1.51-11.57-4.39-18.19-8.57C17.8 8.83 12.3 11.49 7 12.96l-.98.27z'
      id='dataMigr_feature4_svg__Path'
    />
    <path
      className='dataMigr_feature4_svg__st0'
      d='M31 36.88l-5.8-4.78-6.2 4.57V25.2h2v7.51l4.26-3.15L29 32.64V25.2h2z'
      id='dataMigr_feature4_svg__Path-19_1_'
    />
    <path
      id='dataMigr_feature4_svg__Oval-14'
      className='dataMigr_feature4_svg__st1'
      d='M25.13 28.2c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.59 8-8 8zm0-2c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.68 6 6 6z'
    />
    <path
      id='dataMigr_feature4_svg__Oval-14_1_'
      className='dataMigr_feature4_svg__st1'
      d='M25.13 24.2c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.8 4-4 4zm0-2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .89 2 2 2z'
    />
  </svg>
)

export default SvgDataMigrFeature4
