import React from 'react'

const SvgDataMigrArchEn = props => (
  <svg id='dataMigr_arch_en_svg__tuceng_1' x={0} y={0} viewBox='0 0 630.52 370.37' xmlSpace='preserve' {...props}>
    <style>
      {
        '.dataMigr_arch_en_svg__st0{fill:none}.dataMigr_arch_en_svg__st1{fill:#8796aa}.dataMigr_arch_en_svg__st2{fill:#00b5b0}.dataMigr_arch_en_svg__st3{fill:#fd5c1f}.dataMigr_arch_en_svg__st4{fill:none;stroke:#8796aa;stroke-miterlimit:10;stroke-dasharray:4,4}.dataMigr_arch_en_svg__st5{font-family:&apos;OpenSans&apos;}.dataMigr_arch_en_svg__st6{font-size:12px}'
      }
    </style>
    <path className='dataMigr_arch_en_svg__st0' d='M70.72 159.09v45' />
    <path className='dataMigr_arch_en_svg__st1' d='M69.72 159.09h2v45h-2z' />
    <path className='dataMigr_arch_en_svg__st1' d='M76.72 203.51l-6 8-6-8' />
    <path className='dataMigr_arch_en_svg__st0' d='M82.72 211.51v-45' />
    <path className='dataMigr_arch_en_svg__st1' d='M81.72 166.51h2v45h-2z' />
    <path className='dataMigr_arch_en_svg__st1' d='M76.72 167.09l6-8 6 8' />
    <path
      id='dataMigr_arch_en_svg__Combined-Shape_38_'
      className='dataMigr_arch_en_svg__st2'
      d='M578.05 83.59h-3v-33h-41v30h44v3h-47v-36h47z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-17_3_'
      className='dataMigr_arch_en_svg__st2'
      d='M545.05 87.59h19v3h-19z'
    />
    <path id='dataMigr_arch_en_svg__Rectangle-73_3_' className='dataMigr_arch_en_svg__st2' d='M553.05 81.59h3v8h-3z' />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-2_1_'
      className='dataMigr_arch_en_svg__st2'
      d='M540.05 57.59h10v3h-10z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-3_1_'
      className='dataMigr_arch_en_svg__st2'
      d='M540.05 63.59h18v3h-18z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-4_1_'
      className='dataMigr_arch_en_svg__st2'
      d='M540.05 69.59h29v3h-29z'
    />
    <path
      id='dataMigr_arch_en_svg__Combined-Shape_40_'
      className='dataMigr_arch_en_svg__st3'
      d='M578.05 294.32h-3v-33h-41v30h44v3h-47v-36h47z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-17_5_'
      className='dataMigr_arch_en_svg__st3'
      d='M545.05 298.32h19v3h-19z'
    />
    <path id='dataMigr_arch_en_svg__Rectangle-73_5_' className='dataMigr_arch_en_svg__st3' d='M553.05 292.32h3v8h-3z' />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-2_3_'
      className='dataMigr_arch_en_svg__st3'
      d='M540.05 268.32h10v3h-10z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-3_3_'
      className='dataMigr_arch_en_svg__st3'
      d='M540.05 274.32h18v3h-18z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-4_3_'
      className='dataMigr_arch_en_svg__st3'
      d='M540.05 280.32h29v3h-29z'
    />
    <path
      id='dataMigr_arch_en_svg__Combined-Shape_41_'
      className='dataMigr_arch_en_svg__st3'
      d='M339.54 294.32h-3v-33h-41v30h44v3h-47v-36h47z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-17_6_'
      className='dataMigr_arch_en_svg__st3'
      d='M306.54 298.32h19v3h-19z'
    />
    <path id='dataMigr_arch_en_svg__Rectangle-73_6_' className='dataMigr_arch_en_svg__st3' d='M314.54 292.32h3v8h-3z' />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-2_4_'
      className='dataMigr_arch_en_svg__st3'
      d='M301.54 268.32h10v3h-10z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-3_4_'
      className='dataMigr_arch_en_svg__st3'
      d='M301.54 274.32h18v3h-18z'
    />
    <path
      id='dataMigr_arch_en_svg__Rectangle-Copy-4_4_'
      className='dataMigr_arch_en_svg__st3'
      d='M301.54 280.32h29v3h-29z'
    />
    <path className='dataMigr_arch_en_svg__st4' d='M6.72 8.09h140v140h-140z' />
    <g>
      <text
        transform='translate(26.82 118.56)'
        className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'
      >
        {'Local Data Center'}
      </text>
    </g>
    <g>
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M80.54 58.28v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM77.41 47.04c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.7zM53.72 72.42v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94z'
      />
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M53.72 64.7v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01V64.7c-2.48 1.92-7.69 2.94-12.67 2.94S56.2 66.63 53.72 64.7zM80.54 65.49v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14z'
      />
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M53.72 56.98v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.02-12.67-2.94zM88.56 52.2c-4.35 0-8.88-.92-11.15-2.65v1.35c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.73-6.8 2.65-11.16 2.65zM80.54 72.7v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM53.72 80.15v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.02-12.67-2.94z'
      />
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M66.39 50.41c-7.25 0-12.67 2.12-12.67 4.01s5.42 4.01 12.67 4.01 12.67-2.12 12.67-4.01c-.01-1.89-5.42-4.01-12.67-4.01z'
      />
    </g>
    <path className='dataMigr_arch_en_svg__st4' d='M6.72 223.09h140v140h-140z' />
    <g>
      <text
        transform='translate(20.52 333.56)'
        className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'
      >
        {'Remote Data Center'}
      </text>
    </g>
    <g>
      <path
        className='dataMigr_arch_en_svg__st3'
        d='M80.54 273.28v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM77.41 262.04c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.69v.01zM53.72 287.42v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.01-12.67-2.94z'
      />
      <path
        className='dataMigr_arch_en_svg__st3'
        d='M53.72 279.7v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94zM80.54 280.49v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14z'
      />
      <path
        className='dataMigr_arch_en_svg__st3'
        d='M53.72 271.98v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94zM88.56 267.2c-4.35 0-8.88-.92-11.15-2.65v1.35c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.73-6.8 2.65-11.16 2.65zM80.54 287.7v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM53.72 295.15v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94z'
      />
      <path
        className='dataMigr_arch_en_svg__st3'
        d='M66.39 265.41c-7.25 0-12.67 2.12-12.67 4.01s5.42 4.01 12.67 4.01 12.67-2.12 12.67-4.01c-.01-1.89-5.42-4.01-12.67-4.01z'
      />
    </g>
    <g>
      <path className='dataMigr_arch_en_svg__st0' d='M310.04 159.09v45' />
      <path className='dataMigr_arch_en_svg__st1' d='M309.04 159.09h2v45h-2z' />
      <g>
        <path className='dataMigr_arch_en_svg__st1' d='M316.04 203.51l-6 8-6-8' />
      </g>
      <g>
        <path className='dataMigr_arch_en_svg__st0' d='M322.04 211.51v-45' />
        <path className='dataMigr_arch_en_svg__st1' d='M321.04 166.51h2v45h-2z' />
        <g>
          <path className='dataMigr_arch_en_svg__st1' d='M316.04 167.09l6-8 6 8' />
        </g>
      </g>
    </g>
    <path className='dataMigr_arch_en_svg__st4' d='M245.72 8.09h140v140h-140z' />
    <g>
      <text
        transform='translate(266.141 118.56)'
        className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'
      >
        {'Local Data Center'}
      </text>
    </g>
    <g>
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M319.86 58.28v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM316.73 47.04c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.7zM293.04 72.42v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94z'
      />
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M293.04 64.7v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01V64.7c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94zM319.86 65.49v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14z'
      />
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M293.04 56.98v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.02-12.67-2.94zM327.88 52.2c-4.35 0-8.88-.92-11.15-2.65v1.35c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.73-6.8 2.65-11.16 2.65zM319.86 72.7v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM293.04 80.15v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.02-12.67-2.94z'
      />
      <path
        className='dataMigr_arch_en_svg__st2'
        d='M305.71 50.41c-7.25 0-12.67 2.12-12.67 4.01s5.42 4.01 12.67 4.01 12.67-2.12 12.67-4.01c0-1.89-5.42-4.01-12.67-4.01z'
      />
    </g>
    <path className='dataMigr_arch_en_svg__st4' d='M245.72 223.09h140v140h-140z' />
    <g>
      <text
        transform='translate(274.59 333.56)'
        className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'
      >
        {'Tripartite Cloud'}
      </text>
    </g>
    <g>
      <path className='dataMigr_arch_en_svg__st0' d='M548.55 159.09v45' />
      <path className='dataMigr_arch_en_svg__st1' d='M547.55 159.09h2v45h-2z' />
      <g>
        <path className='dataMigr_arch_en_svg__st1' d='M554.55 203.51l-6 8-6-8' />
      </g>
      <g>
        <path className='dataMigr_arch_en_svg__st0' d='M560.55 211.51v-45' />
        <path className='dataMigr_arch_en_svg__st1' d='M559.55 166.51h2v45h-2z' />
        <g>
          <path className='dataMigr_arch_en_svg__st1' d='M554.55 167.09l6-8 6 8' />
        </g>
      </g>
    </g>
    <path className='dataMigr_arch_en_svg__st4' d='M484.55 8.09h140v140h-140z' />
    <g>
      <text transform='translate(510.827 118.56)'>
        <tspan x={0} y={0} className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'>
          {'Tripartite Cloud '}
        </tspan>
        <tspan
          x={21}
          y={14.4}
          className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'
        >
          {'Platform'}
        </tspan>
      </text>
    </g>
    <path className='dataMigr_arch_en_svg__st4' d='M484.55 223.09h140v140h-140z' />
    <g>
      <text
        transform='translate(512.099 333.56)'
        className='dataMigr_arch_en_svg__st1 dataMigr_arch_en_svg__st5 dataMigr_arch_en_svg__st6'
      >
        {'Tripartite Cloud'}
      </text>
    </g>
  </svg>
)

export default SvgDataMigrArchEn
