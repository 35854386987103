import React from 'react'

const SvgDataMigrFeature3 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path d='M25 2.01L5 13.44v22.84L25 47.7l20-11.43V13.44L25 2.01z' fill='none' stroke='#334966' strokeWidth={2} />
    <path fill='#fd5c1f' d='M25 11.86l11 6.5v13l-11 6.5-11-6.5v-13z' />
    <path
      d='M45.01 13.12l-1.01.57v.32l1.01.58.42-.74-.42-.73zm-1.29.74L25 3.16 6.28 13.86 25 24.55l18.72-10.69zM6 14.02v-.32l-1.01-.58-.42.74.42.74L6 14.02zm20 12.26v21.57h-2V26.28L4 14.86v-2l21-12 21 12v2L26 26.28z'
      fill='#334966'
    />
  </svg>
)

export default SvgDataMigrFeature3
